.torso {
    margin-left: 0;
    padding: 5rem 2rem;
    min-height: 100%;
    position: relative;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    .container-fluid {
        max-width: 1590px;
    }
    .card {
        background: #fff;
        margin-top: 2rem;
    }
    .card.card-text {
        padding: 1rem 2rem;
    }
}

@include media-breakpoint-up(lg) {
    .torso {
        margin-left: 274px;
    }
}

.page-title {
    color: $jr-blue-300;
    border-bottom: 1px solid $jr-blue-300;
    margin: 1rem 0 2rem;
    font-size: 2.25rem
}

.list-group-item.border-none li {
    border: none;
}

.action-btns {
    margin: -.5rem 0 1rem;
    .btn-group {
        .dropdown-item {
            font-size: .875rem;
            width: 100%;
            i {
                margin-right: .25rem;
                font-size: .9rem;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .action-btns {
        margin: 0;
        position: fixed;
        top: .8rem;
        right: 2rem;
        width: auto;
        z-index: 2000;
    }
}

.action-btns .btn {
    position: relative;
    top: 0;
    right: 0;
    line-height: 1.2;
    i {
        margin-right: .25rem;
        font-size: 1rem;
    }
    + .btn {
        margin-left: .5rem;
    }
}

.btn-modal {
    cursor: pointer
}

.btn-modal-nohover {
    color: #9e9e9e;
    &:hover {
        text-decoration: none;
        color: #9e9e9e;
    }
}

table {
    border-spacing: 0;
}
.modal-backdrop {z-index: 3500;}
.modal {z-index: 4000;}
#modalCV {
    .modal-header {
        z-index:1;
        button {
            color: $white;
            text-shadow: 0 1px 1px rgba($black,.25);
            position: absolute;
            right: 1.5rem;
            top: auto;
        }
    }
    img {
        margin-top: -40px;
    }
    h2 {
        color: $jr-blue-300;
        font-size: 1.8rem;
        text-transform: capitalize;
    }
}

//Dashboard
.case-group {
    .list-group-item {
        padding: 1rem 0;
        .small {
            font-style: italic;
            color: $material-color-grey-500;
        }
        .lead {
            font-size: 1rem;
            a {
                color: $jr-blue-300;
            }
        }
        .badge {
            margin-top: .5rem;
            font-size: .75rem;
            padding: .35rem .75rem;
            i {
                font-size: .85rem;
                margin-right: .5rem;
            }
            a {
                color: $white;
                text-decoration: underline;
                margin-left: .25rem;
            }
        }
    }
}
.case-title_lead {
    font-size: 1rem;
    color: $jr-blue-300;
}
//notes
.notes {
    .note-block {
        margin-top: 1rem;
        flex-direction: row;
        justify-content: space-between;
        .note-user {
            width: 30%;
        }
        .note-content {
            width: 70%;
            background: $material-color-grey-100;
            padding: 1rem;
        }
    }
}

//tags
.tag-container {
    margin: 0 -.5rem;
    .tm-tag {
        @extend .badge;
        @extend .badge-info;
        @extend .btn;
        @extend .m-2;
        @extend .btn-info;
        span {
            margin-right: .5rem;
        }
        .tm-tag-remove {
            @extend .badge;
            @extend .badge-light;
        }
    }
}


//specialty
.specialty {
    select + .select2 {
        width: auto !important;
        flex: 1 1 auto;
    }
}

.contact_preferences {
    .card-body {
        padding: .5rem 1rem;
    }
    ul {
        padding: 0;
        list-style: none;
        margin: 0;
        li {
            padding: .5rem 0;
            display: flex;
            .contact_icon {
                width: 30px;
                i {
                    color: #549abd;
                }
            }
            .contact_info {
                margin-left: .5rem;
                small {
                    display: block;
                    margin-top: .25rem;
                    font-size: 90%;
                }
            }
            + li {
                border-top: 1px solid #ebebeb;
            }
        }
    }
}
.case_overview {
    .control-group {
        display: flex;
        padding: .5rem 0;
        .control-label {
            width: 30%;
            color: rgba($black, .75);
        }
        .controls {
            margin-left: .5rem;
            width: 70%;
        }
        + .control-group {
            border-top: 1px solid #ebebeb;
        }
    }
}
table .list-group label {
    color: rgba($black, .65);
}
.edit-case-stage {
    color: #03a9f4;
    cursor: pointer;
    &:hover {
        color: #03a9f4;
    }
}
hr {
    background: #f3f6f8;
    display: block;
    border: none;
    height: 7px;
    margin: 1rem -1rem;
    box-shadow: 0 0 5px inset rgba(0,0,0,.25);
}
#modalTemplatingHelp {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li {
            width: 100%;
            display:flex;
            align-items: center;
            i {
                cursor: pointer;
                font-size: 16px;
            }
            + li {
                margin-top: 2px;
            }
            strong {
                font-weight: normal;
            }
            code {
                margin: 0 .5rem;
            }
        }
        + h4 {
            margin-top: 1rem;
        }
    }
}
