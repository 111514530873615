.progress_checklist {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem;
    .check {
        padding: .25rem .5rem;
        width: calc(100%/2 - 1rem);
        i {
            width: 30px;
        }
    }
}
#progress-circle {
    position: relative;
    .progress-value{
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: absolute;
        font-weight: bold;
        font-size: 2.2rem;
    }
}