table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    top: 50%;
    transform: translateY(-50%);
}
#table-cases_wrapper .dataTables_filter, .default-filter-remove .dataTables_filter {
    display: none;
}

/* Move tfood in the upper part of the table */
tfoot {
    display: table-header-group;
}
.table thead td, .table thead th, .table tfoot td, .table tfoot th, .table tbody td, .table tbody th {
    height: auto;
}
.dataTables_length, .dataTables_filter {
    padding: 1rem 1.5rem;
}
.dataTables_info {
    padding-left: 1.5rem;
    padding-bottom: 1rem;
}
