.hamburger-slim {
    background: transparent;
    left: 1rem;
    position: absolute;
    height: 30px;
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 500;
    &::before, &::after {
        background: #549abd;
        backface-visibility: hidden;
        content: '';
        height: 2px;
        left: 0;
        transition: 0.75s;
        width: 40px;
    }
    &::before {
        box-shadow: #549abd 0 14px 0 0;
        position: absolute;
        top: 0;
    }
    &::after {
        position: absolute;
        top: calc(100% - 2px);
    }
    &:hover {
        &::before, &::after {
            background: #96cce8;
        }
    }
    &.hamburger-toggle {
        &::before, &::after {
            background: #96cce8;
        }
        &::before {
            box-shadow: transparent 0 0 0 0;
            top: 50%;
            transform: rotate(225deg);
        }
        &::after {
            top: 50%;
            transform: rotate(315deg);
        }
    }
}


@media (min-width: 992px) {
    .hamburger-slim {
        display: none;
    }
}