.select2-container--material {
    width: 100% !important;
    ::placeholder {
        color: inherit;
    }
    /**
     * Textbox
     */
    .select2-selection {
        /* @extend input */
        overflow: visible;
        font: inherit;
        touch-action: manipulation;
        margin: 0;
        line-height: inherit;
        border-radius: 0;
        box-sizing: inherit;

        /* @extend .form-control */
        display: block;

        width: 100%;
        color: #55595c;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,0.42);
        padding: .5rem 0 .6rem;
        font-size: 1rem;
        line-height: 1.5;
        background-color: transparent;
        background-image: none;
        border-radius: 0;
        margin-top: .2rem;
        margin-bottom: 1rem;

        /* @extend input[type=text] */
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(0,0,0,0.42);
        border-radius: 0;
        outline: 0;
        //height: 2.1rem;
        width: 100%;
        font-size: 1rem;
        box-shadow: none;
        transition: all .3s;
        min-height: 2.1rem;
        .select2-selection__rendered {
            padding-left: 0;
        }
    }

    .select2-selection--single {
        .select2-selection__rendered {
            float: left;
        }
        .select2-selection__arrow {
            float: right;
        }
    }

    .select2-selection--multiple {
        .select2-selection__rendered {
            width: 100%;
            li {
                list-style: none;
            }
        }

        /**
         * Multiple selected options
         */
        .select2-selection__choice {
            /* @extend .mdl-chip */
            height: 32px;
            //font-family: "Roboto","Helvetica","Arial",sans-serif;
            line-height: 32px;
            padding: 0 12px;
            border: 0;
            border-radius: 16px;
            background-color: #dedede;
            display: inline-block;
            color: rgba(0,0,0,.87);
            margin: 2px 0;
            white-space: nowrap;

            /* @extend .mdl-chip__text */
            font-size: 13px;
            vertical-align: middle;
            display: inline-block;
            float: left;
            margin-right: 8px;
            margin-bottom: 4px;
        }

        /**
         * Multiple selected option clear button
         */
        .select2-selection__choice__remove {
            /* Hide default content */
            font-size: 0;
            opacity: 0.38;
            cursor: pointer;
            float: right;
            margin-top: 4px;
            margin-right: -6px;
            margin-left: 6px;
            transition: opacity;
            &::before {
                content: "cancel";

                /* @extend .material-icons */
                font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
                color: #000;
            }

            &:hover {
                opacity: 0.54;
            }
        }
    }

    .select2-search--inline {
        .select2-search__field {
            width: 100%;
            margin-top: 0;

            /* Match input[type=text] */
            height: 34px;
            line-height: 1;
        }
    }



    /**
     * Dropdown
     */
    .select2-dropdown {
        border: 0;
        .select2-search__field {
            min-height: 2.1rem;
            margin-bottom: 0;
            border: 0;
            //border-bottom: 1px solid #ccc;
            transition: all .3s;
            background: #ebebeb;
            &:focus {
                border-bottom: 1px solid $material-color-pink-a200;
                box-shadow: 0 1px 0 0 $material-color-pink-a200;
            }
        }
    }

    .select2-results__options {
        /* @extend .zf-shadow-depth* */
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);

        /* @extend .dropdown-content */
        background-color: #fff;
        margin: 0;
        //display: none;
        min-width: 100px;
        max-height: 650px;
        overflow-y: auto;
        //opacity: 0;
        //position: absolute;
        z-index: 999;
        will-change: width,height;

        /* @extend .dropdown-content inline styles */
        //position: absolute;
        //top: 0;
        //left: 0;
        //opacity: 1;
        //display: block;

        &--above {
            //top: 50px;
        }

        &--below {
            //top: -50px;
        }
    }



    /**
     * Options
     */

    .select2-results__option {
        /* @extend .dropdown-content li */
        cursor: pointer;

        clear: both;
        color: rgba(0,0,0,.87);
        line-height: .5rem;
        //width: 100%;
        text-align: left;
        text-transform: none;

        /* @extend .dropdown-content li>a, .dropdown-content li>span */
        font-size: 1rem;
        //color: #4285F4;
        display: block;
        padding: 1rem;

        /**
         * Disabled options
         */
        &[aria-disabled=true] {
            /* @extend .select-dropdown li.disabled */
            color: rgba(0,0,0,.3);
            background-color: transparent!important;
            cursor: context-menu;

            /* @extend .disabled */
            cursor: not-allowed;
        }

        /**
         * Selected option
         */
        &[aria-selected=true] {
            /* @extend .dropdown-content li:active, .dropdow-content li:hover */
            color: $material-color-pink-a200;
            background-color: #eee;
        }

        /**
         * Active/hovered option
         */
        &--highlighted[aria-selected] {
            background-color: #ddd;
        }
    }



    /**
     * Focused textbox
     */

    &.select2-container--focus {
        .select2-selection {
            /* @extend input[type=text]:focus */
            border-bottom: 1px solid $material-color-pink-a200;
            box-shadow: 0 1px 0 0 $material-color-pink-a200;
        }
    }



    /**
     * Disabled textbox
     */

    &.select2-container--disabled {
        .select2-selection {
            /* @extend .select-wrapper input.select-dropdown:disabled */
            color: rgba(0,0,0,.3);
            cursor: default;
            user-select: none;
            border-bottom: 1px solid rgba(0,0,0,.3);
        }

        &.select2-container--focus {
            .select2-selection {
                box-shadow: none;
            }
        }
    }
}
select.form-control[multiple] {
    margin-top: 1rem;
}
.select-multiple + .select2-container--material {
    .select2-selection--multiple {
        padding-bottom: 0;
        > ul {
            margin-bottom: 0;
        }
    }
}
.l2 {
    padding-left: 1em;
}