//Material Design Components
$nav-tab-color-active: #03a9f4;
$nav-tab-indicator-bg: #03a9f4;

@import '~daemonite-material/assets/scss/material';

//Select2
@import '~select2/src/scss/core';


//theme files
@import "theme/components/fonts";
@import "theme/utilities/utilities";

@import "theme/global";
@import "theme/pages";


@import "theme/hamburger";
@import "theme/nav";

@import "theme/tables";

@import "theme/components/forms";
@import "theme/components/form-layouts/login";


@import "theme/overrides/progress";
@import "theme/overrides/select2";
@import "theme/overrides/datatables";
