@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('~material-design-icons/iconfont/MaterialIcons-Regular.eot'); /* IE9 Compat Modes */
    src: local('MaterialIcons-Regular'),
    url('~material-design-icons/iconfont/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~material-design-icons/iconfont/MaterialIcons-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('~material-design-icons/iconfont/MaterialIcons-Regular.woff') format('woff'), /* Modern Browsers */
    url('~material-design-icons/iconfont/MaterialIcons-Regular.ttf') format('truetype') /* Safari, Android, iOS */
    //url('~material-design-icons/iconfont/MaterialIcons-Regular.svg#MaterialIcons-Regular') format('svg'); /* Legacy iOS */
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}