body {
    textarea.form-control:not([rows='1']) {
        border: none;
        border-bottom: 1px solid rgba(0,0,0,0.42);
        border-radius: 0;
        min-height: 15rem;
        background: #f5f5f5;
        padding: 1rem;
        margin-top: .5rem;
        &:hover {
            box-shadow: inset 0 -2px 0 -1px rgba(0,0,0,0.87);
        }
        &:focus {
            box-shadow: inset 0 -2px 0 -1px  $material-color-blue-a200;
            border-color: $material-color-blue-a200;
            outline: 0;
        }
    }
    .floating-label.is-focused label {
        color:  $material-color-blue-a200;
    }
    .custom-select:focus, .form-control-file:focus, .form-control:focus {
        border-color:  $material-color-blue-a200;
        box-shadow: inset 0 -2px 0 -1px $material-color-blue-a200;
    }
    .select2-container--material.select2-container--focus .select2-selection {
        border-bottom: 1px solid $material-color-blue-a200;
        box-shadow: 0 1px 0 0 $material-color-blue-a200;
    }
    .select2-container--material .select2-results__option[aria-selected=true] {
        color: $material-color-blue-a200;
    }
    .select2-container--material .select2-results__option {
        line-height: 1.4;
    }
}
.mask {
    display: block;
    background-clip: padding-box;
    background-color: transparent;
    border-radius: 0;
    border: solid rgba(0,0,0,.42);
    border-width: 0 0 1px;
    box-shadow: none;
    color: rgba(0,0,0,.87);
    font-size: 1rem;
    line-height: 1.5;
    padding: .375rem 0 calc(.375rem - 1px);
    width: 100%;
    &:focus {
        outline: none;
        border-color: #448aff;
        box-shadow: inset 0 -2px 0 -1px #448aff;
    }
    &:hover {
        border-color: rgba(0,0,0,.87);
        box-shadow: inset 0 -2px 0 -1px rgba(0,0,0,.87);
    }
}
