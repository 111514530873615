.col-action-btns{
    white-space: nowrap;
    width: 1%;
    text-align: right;
    .btn {
        height: 2rem;
        line-height: 2rem;
        width: 2rem;
        i {
            font-size: 1.125rem;
            line-height: 2rem;
        }
        + .btn { margin-left: .25rem;}
    }
    .btn-group {
        button {
            width: 100%;
            height: auto;
            line-height: 1;
        }
        .dropdown-item {
            font-size: .8rem;
            width: auto;
            i {
                font-size: 1rem;
            }
        }
    }
}
td.col-date {
    white-space: nowrap;
    width: 1%;
}
#table-cases {
    tbody td {
        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
            white-space: nowrap;
        }
    }
    tfoot input {
        font-size: 14px;
    }
}
