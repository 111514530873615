html,
body {
    height: 100%
}

body {
    position: relative;
    margin: 0;
    background-color: #f3f6f8;
    a {
        color: #03a9f4;
        &:hover {
            color: #03a9f4;
        }
    }
}
img {
    width: 100%;
    height: auto;
}
.round {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    margin-right: 1rem;
    i {
        color: #fff;
        font-size: 1.8rem;
        line-height: 64px;
        text-align: center;
        display: block;
    }
}
.mp-1 {
    margin-left: -1rem; margin-right: -1rem;
}
.col-active {
    .custom-switch .custom-control-input:checked ~ .custom-control-track {
        background-color: rgba($material-color-green-500, .25);
    }
    .custom-control-input:checked ~ .custom-control-label:before, .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
        background-color: $material-color-green-500;
    }
    .custom-control-input:checked ~ .custom-control-label:after {
        color: $material-color-green-500;
    }
    .custom-switch .custom-control-track {
        height: 1.125rem;
        width: 2.75rem;
    }
}
